// App.js

import React from 'react';
import styled from 'styled-components';
import { useState, Image } from "react";
import { useNavigate } from 'react-router-dom';
import  logo  from './images/file.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  height: 100vh;
  background-color: #d7f9ef;
  margin-top: 10px;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 450px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #159570;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  color: white !important;
`;

const TitleFooter = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #0f3e23;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 40px;

  &:hover {
    background-color: #2980b9;
  }
`;

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
const useHandleLogin = () => {
    if (email === '' || password === '') {
        alert('Preencha todos os campos');
        return;
    }
    window.location = '/';
};




  return (
    <Container>
      <img style={{width: "50%"}} src={logo} />
      <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: "20px", marginBottom: "10px"}}>Transformando resíduos em recursos, <br /> de Norte a Sul do Brasil</p>
      <LoginBox>
        <Title>
          Olá! <br />
          Que bom ter você por aqui! <br/>
          Para começar, informe seu <br />
          e-mail e senha cadastrados. 
        </Title>
        <Input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <Input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Senha" />
        <Button onClick={useHandleLogin}>Login</Button>
      </LoginBox>
      <TitleFooter> Ainda não tem cadastro? <br /> Registre-se aqui </TitleFooter>
    </Container>
  );
};

export default Login;
