export const markersData = [
    {
      id: 1,
      name: "João",
      image: 'https://via.placeholder.com/150',
      phone: '11 97781-7234',
      tipoVeiculo: 'Carroça',
      address: 'Rua 25 de Março, 100',
      position: { lat: -23.548870, lng: -46.630379 },
      materiais: 'Papelão, Plástico, Vidro'
    },
    {
      id: 2,
      name: "Lucas",
      tipoVeiculo: 'Carroça',
      image: 'https://via.placeholder.com/150',
      phone: '11 99999-9999',
      address: 'Rua São Bento, 500',
      position: { lat: -23.5485616, lng: -46.6364789 },
      materiais: 'Papelão, Plástico, Vidro'
    },
    {
      id: 3,
      name: "Gabriel",
      tipoVeiculo: 'Carro',
      image: 'https://via.placeholder.com/150',
      phone: '11 96654-8912',
      address: 'Avenida Ipiranga, 200',
      position: { lat: -23.546173095703125, lng: -46.64479446411133 },
      materiais: 'Papelão, Lata'
    },
    {
      id: 4,
      name: "Pedro",
      tipoVeiculo: 'Carroça',
      image: 'https://via.placeholder.com/150',
      phone: '11 99999-9999',
      address: 'Avenida São João, 150',
      position: { lat: -23.545020, lng: -46.634300 },
      materiais: 'Papelão, Plástico, Vidro'
    },
    {
      id: 5,
      name: "Lucas",
      tipoVeiculo: 'Carroça',
      image: 'https://via.placeholder.com/150',
      phone: '11 99999-9999',
      address: '123 Main St, Anytown, USA',
      position: { lat: -23.545020, lng: -46.634300 },
      materiais: 'Papelão, Plástico, Vidro'
    }
  ];