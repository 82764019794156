import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css'; 
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import Main  from './Main';
import Login from './Login';
import Catador from './Catador';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
      <Route path="/" element={<Main />}/>
      <Route path="/login" element={<Login />} />
      <Route path="/catador/:id" element={<Catador />} />
      </Routes>
    </BrowserRouter>
);

