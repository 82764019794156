// src/App.js
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import SearchBox from './SearchBox';
import { markersData } from './markers'
import photo from './images/photo.png';

const mapContainerStyle = {
  height: '50vh',
  width: '100vw',
  marginTop: '40px'
};



const App = () => {
  const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [center, setCenter] = useState({ lat: -23.5489, lng: -46.6388 });
  const handleSearch = (query) => {
    // Aqui você faria uma chamada para uma API de lugares (como Google Places)
    // Vou adicionar um exemplo de marcador fixo para simplificação
    setMarkers(markersData);
  
  };

  useEffect(() => {
    if (markers.length === 0) return;
    setCenter(markers[0].position)
  }, [markers]);

  return (
    <div style={{textAlign: 'center'}}>
      <h1 style={{alignContent: 'center', fontSize: '28px', fontWeight: 'bold', marginTop: '20px' }}>Buscar catadores</h1>
      <SearchBox onSearch={handleSearch} />
      <LoadScript googleMapsApiKey="AIzaSyBRg28LBZJPLi8hZWLSQr5jZ-wWHm09Djk">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={13}
        >
          {markers.map(marker => (
             <Marker
            key={marker.id}
            position={marker.position}
            onClick={() => setActiveMarker(marker)}
            >
            {activeMarker?.id === marker.id ? (
                <InfoWindow
                 onCloseClick={() => setActiveMarker(null)}>
                <div style={{width: "200px", textAlign: 'center'}}>
                <div>{marker.name.split(' ')[0]}</div>
                <img style={{width:100, textAlign: "center", margin: "0 auto"}} srcSet={photo}  />
                <a href={`/catador/${marker.id}`}>Ver perfil</a>
                </div>
                </InfoWindow>
            ) : null}
            </Marker>
          ))}
        </GoogleMap>
      </LoadScript>
      <div>
        {activeMarker ? (
          <div style={{color: 'white', backgroundColor: '#25a85a', marginTop: '25px',width: '70%',border: '2px solid', borderRadius: '30px', textAlign: 'center', margin: '0 auto'}}>
            <p style={{fontWeight: 'bold', fontSize: '18px'}}>{activeMarker.name}</p>
            <p>{activeMarker.tipoVeiculo}</p>
            <p><strong>Telefone:</strong> {activeMarker.phone}</p>
            <p><strong>Endereço:</strong> {activeMarker.address}</p>
            <a href={`/catador/${activeMarker.id}`}>Ver perfil</a>
          </div>
        ): ''}
       
      </div>
    </div>
  );
};

export default App;
