// src/SearchBox.js
import React, { useState } from 'react';

const SearchBox = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <form onSubmit={handleSubmit} style={{marginTop: '40px'}}>
      <input 
        type="text" 
        value={query} 
        onChange={(e) => setQuery(e.target.value)} 
        placeholder="Nome"
        style={{padding: '10px', width: '90%', borderRadius: '5px', border: '2px solid #ccc'}}
      />
      <button type="submit"
      style={{width: '50%', padding: '10px',  backgroundColor: '#0f3e23', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', fontSize: '16px', marginTop: '20px'}}
      >Buscar</button>
    </form>
  );
};

export default SearchBox;
