// src/UserProfile.js
import React from 'react';
import './Catadores.css';
import {markersData} from './markers';
import {useParams} from 'react-router-dom';
import photo from './images/photo.png';
import logo from './images/logoclean.png';

const Catador = () => {
    const {id} = useParams();
    console.log(id)
    const user = markersData.find(marker => marker.id == id);
    console.log(user);

  return (
    <>
    <img style={{width: "50%", margin: '0 auto'}} src={logo} />
    <div style={{color: 'white', backgroundColor: '#25a85a', marginTop: '50px',width: '90%',border: '2px solid', borderRadius: '30px', textAlign: 'center', margin: '0 auto'}} className="user-profile">
      <div style={{textAlign: 'center', color: 'white', margin: '0 auto'}}>
        <h1 className="profile-name">{user.name} | {user.tipoVeiculo}</h1>
      </div>
      <div><strong>Materiais coletados:</strong></div>
      <p>{user.materiais}</p>
      <div >
        <p><strong>Telefone:</strong> {user.phone}</p>
        <p><strong>Endereço:</strong> {user.address}</p>
      </div>
    </div>
    <div className="back-link" style={{width: '100vw', textAlign: "center"}}>
        <a href="/">Voltar para Mapa</a>
    </div>
    </>
  );
};

export default Catador;
